"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineMemoBoardPrivate = exports.MemoBoardPrivate = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/document-editor/lib-es2015");
const lib_es2015_3 = require("@hodol/web-window/lib-es2015");
const lib_es2015_4 = require("@hodol/dom-helper/lib-es2015");
const apis_1 = require("./apis");
const entity_1 = require("./entity");
class MemoBoardPrivate extends HTMLElement {
    constructor() {
        super();
        this.isPrivate = true;
        this.apis = new apis_1.default();
        this.convertRecordableSourceToPresentable = (htmlSource) => {
            const recordable = (0, lib_es2015_4.buildNodeByOuterHTML)(htmlSource);
            this.documentEditor.convertRecordableToPresentable(recordable);
            return recordable;
        };
        this.setupMemoBoard = () => {
            this.desktop = new lib_es2015_3.Desktop(this.memoBoardContainer);
            this.settings = new lib_es2015_3.Settings(this.desktop);
        };
        this.buildMemo = (w, entity) => {
            w.setValue('key', entity.id);
            w.setStyle(JSON.parse(entity.style_set));
            console.log(`memoId: ${entity.id}, parsedStyle:`, entity.style_set);
            const title = this.convertRecordableSourceToPresentable(entity.title);
            w.setTitle(title);
            const content = this.convertRecordableSourceToPresentable(entity.content);
            const scrollView = (0, lib_es2015_4.buildNodeByOuterHTML)(`<div class="scroll-view"></div>`);
            scrollView.appendChild(content);
            w.setBody(scrollView);
        };
        this.buildAndPutMemo = (entity) => {
            const w = new lib_es2015_3.Window(this.desktop, lib_es2015_3.DefaultStyleSet, this.settings);
            this.buildMemo(w, entity);
            const editButton = (0, lib_es2015_4.buildNodeByOuterHTML)(`<button>E</button>`);
            const saveButton = (0, lib_es2015_4.buildNodeByOuterHTML)(`<button style="display: none;">Save</button>`);
            const cancelButton = (0, lib_es2015_4.buildNodeByOuterHTML)(`<button style="display: none;">Cancel</button>`);
            editButton.addEventListener('click', () => {
                editButton.style.setProperty('display', 'none');
                saveButton.style.setProperty('display', 'inline-block');
                cancelButton.style.setProperty('display', 'inline-block');
                this.documentEditor.convertPresentableToEditable(w.getTitle());
                this.documentEditor.convertPresentableToEditable(w.getBody());
                this.documentEditor.contentEditor = w.getBody();
                this.documentEditor.addDefaultEventListenersOnContentEditor();
                this.toggleEdit(true);
            });
            cancelButton.addEventListener('click', () => {
                void (() => __awaiter(this, void 0, void 0, function* () {
                    if (!confirm(`You will lost all modification, continue?`))
                        return;
                    editButton.style.setProperty('display', 'inline-block');
                    saveButton.style.setProperty('display', 'none');
                    cancelButton.style.setProperty('display', 'none');
                    const oldEntity = yield this.apis.getByKey({ id: entity.id });
                    if (oldEntity === null) {
                        alert(`fail to get old memo!, try later.`);
                        return;
                    }
                    this.buildMemo(w, oldEntity);
                    this.toggleEdit(false);
                }))();
            });
            saveButton.addEventListener('click', () => {
                void (() => __awaiter(this, void 0, void 0, function* () {
                    var _a;
                    if (!confirm(`Old data will be lost, continue?`))
                        return;
                    const clonedTitle = (_a = w.getTitle().parentNode) === null || _a === void 0 ? void 0 : _a.cloneNode(true);
                    const clonedContent = w.getBody().cloneNode(true);
                    this.documentEditor.convertEditableToRecordable(clonedTitle);
                    this.documentEditor.convertEditableToRecordable(clonedContent);
                    const upsertedEntity = entity.id === 0
                        ? yield this.apis.insert({
                            title: clonedTitle.innerHTML,
                            content: clonedContent.innerHTML,
                            style_set: JSON.stringify(w.getStyleSet()),
                            is_private: this.isPrivate,
                        })
                        : yield this.apis.update(entity.id, {
                            title: clonedTitle.innerHTML,
                            content: clonedContent.innerHTML,
                            style_set: JSON.stringify(w.getStyleSet()),
                            is_private: this.isPrivate,
                        });
                    if (upsertedEntity === null) {
                        alert(`failed to insert! try later!`);
                        return;
                    }
                    this.buildMemo(w, upsertedEntity);
                    editButton.style.setProperty('display', 'inline-block');
                    saveButton.style.setProperty('display', 'none');
                    cancelButton.style.setProperty('display', 'none');
                    this.toggleEdit(false);
                }))();
            });
            w.addButton(editButton);
            w.addButton(cancelButton);
            w.addButton(saveButton);
        };
        this.onClickAddMemoBtn = () => {
            this.buildAndPutMemo((0, entity_1.newEntity)());
        };
        this.fetchItems = () => __awaiter(this, void 0, void 0, function* () {
            const apiResp = yield this.apis.list(this.isPrivate);
            if (apiResp === null) {
                return;
            }
            apiResp.forEach(item => {
                this.buildAndPutMemo(item);
            });
        });
        this.updateAllStyles = () => __awaiter(this, void 0, void 0, function* () {
            return yield this.apis.updateStyles(this.desktop
                .getWindows()
                .filter(item => item instanceof lib_es2015_3.Window)
                .map(w => ({
                id: w.getValue('key'),
                style_set: JSON.stringify(w.getStyleSet()),
            })));
        });
        this.toggleEdit = (isEdit) => {
            if (isEdit) {
                this.toolbarContainer.style.setProperty('display', 'block');
                this.addMemoBtn.style.setProperty('display', 'none');
            }
            else {
                this.toolbarContainer.style.setProperty('display', 'none');
                this.addMemoBtn.style.setProperty('display', 'block');
            }
        };
        this.style.setProperty('display', 'block');
        // Setup functional style
        if (document.head.querySelector('style[data-scroll-view="true"]') === null) {
            document.head.appendChild((0, lib_es2015_4.buildNodeByOuterHTML)(`<style data-scroll-view="true">
            div.scroll-view {
              height: 100%;
              overflow: auto;
            }
            div.scroll-view:hover::-webkit-scrollbar-thumb {
              background-color: #ddd;
              border-radius: 2px;
            }
            ::-webkit-scrollbar {
              width: 5px;
              height: 5px;
            }
            ::-webkit-scrollbar-track {
              border-radius: 10px;
            }
            ::-webkit-scrollbar-thumb {}
            ::-webkit-scrollbar-corner {
              background-color: inherit;
            }
        </style>`));
        }
        this.innerHTML = `
    <div class="memo-board-container">
      <div class="toolbar-container"></div>
      <button class="add-memo-btn">+</button>
    </div>`;
        this.memoBoardContainer = (0, lib_es2015_4.getQuerySelectOrThrowError)(this, 'div.memo-board-container');
        this.toolbarContainer = (0, lib_es2015_4.getQuerySelectOrThrowError)(this, 'div.toolbar-container');
        this.addMemoBtn = (0, lib_es2015_4.getQuerySelectOrThrowError)(this, 'button.add-memo-btn');
        this.addMemoBtn.addEventListener('click', e => this.onClickAddMemoBtn());
        this.documentEditor = new lib_es2015_2.DocumentEditor(document.createElement('DIV'), // dummy...
        this.toolbarContainer, '/files/customize1/styles/fonts');
        // For loading the CSS document view.
        this.documentViewer = new lib_es2015_2.DocumentViewer();
        this.setupMemoBoard();
        window.onbeforeunload = () => __awaiter(this, void 0, void 0, function* () { return yield this.updateAllStyles(); });
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.fetchItems();
        });
    }
}
exports.MemoBoardPrivate = MemoBoardPrivate;
const defineMemoBoardPrivate = (name) => {
    (0, lib_es2015_1.defineIfUndefined)(name, MemoBoardPrivate);
};
exports.defineMemoBoardPrivate = defineMemoBoardPrivate;
