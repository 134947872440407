"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.newEntity = newEntity;
const lib_1 = require("@hodol/web-window/lib");
function newEntity() {
    return {
        id: 0,
        title: '<div class="js-memo-header" style="font-weight: bold; font-size: 1.2em; user-select: none; padding: 5px; outline: none; cursor: text;" data-contenteditable="true"></div>',
        content: '<div class="js-memo-body memo-body hodol-document-editor-content presentable" style="padding: 5px; outline: none; overflow: auto;" data-contenteditable="true"></div>',
        style_set: JSON.stringify(lib_1.DefaultStyleSet),
        created_at: '',
        created_by: '',
        updated_at: '',
        updated_by: '',
        is_private: false,
    };
}
